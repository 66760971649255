export default function app () {
  // slider
  const slider = document.querySelector('.slider');
  const slides = document.querySelectorAll('.slider__content__slide');
  const sliderBtns = document.querySelector('.slider__btns');
  const sliderTime = 6000;
  let slideId = 0;

  const createSlideBtn = () => {
    slides.forEach((slide, index) => {
      const btn = document.createElement('button');
      btn.className = (index === 0) ? 'slider__btn active' : 'slider__btn';
      btn.setAttribute('data-slide', index);
      sliderBtns.appendChild(btn);
    })
  };

  const slideHandle = () => {
    const sliderBtns = document.querySelectorAll('.slider__btn');

    sliderBtns.forEach(btn => {
      let slideIndex = parseInt(btn.dataset.slide);

      btn.addEventListener('click', () => {
        slideActive(slideId = slideIndex);
      });
    })
  };

  const slideActive = index => {
    if (index >= slides.length) slideId = 0;

    slides.forEach(slide => {
      slide.classList.remove('active');
    })
    slides[slideId].classList.add('active');

    const sliderBtns = document.querySelectorAll('.slider__btn');
    sliderBtns.forEach(btn => {
      btn.classList.remove('active')
    })
    sliderBtns[slideId].classList.add('active');
  };

  const slideAuto = () => {
    setInterval(() => {
      slideActive(slideId = slideId + 1);
    }, sliderTime);
  };

  createSlideBtn();
  slideHandle();
  slideAuto();
}