import GET_ARTICLES from "../class/getArticles";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import slider from "./slider";

gsap.registerPlugin(ScrollTrigger);

const [d] = [document];

class BLOCKS {
  constructor() {
    this.news('.block-news__list[data-plugin="getArticles"]');
  }

  news(selector) {
    const getArticle = new GET_ARTICLES(selector);
    const getCategories = this.fetchCategories('news');
    const categoryContainer = d.querySelectorAll('.block-news [data-plugin="getCategories"]');
    getArticle.makeItem = item => `
      <a href="${item.href}" class="block-news__item">
        <div class="block-news__item-img"><img src="${item.thumb}" alt="${item.title}"></div>
        <!-- /.block-news__item-img -->
        <div class="block-news__item-body">
          <span class="cate">${item.category.name}</span>
          <span class="date">${item.date}</span>
          <span class="title${item.is_new ? ' is-new' : ''}">${item.title.str_truncate(30)}</span>
        </div>
        <!-- /.block-news__body -->
      </a>`;
    getArticle.render().then(([[art, cat]]) => {
      if (art.length < 1) {
        d.querySelectorAll(selector).forEach(obj => {
          let parent = obj.dataset.parent ? obj.closest(obj.dataset.parent) : '';
          if (parent) parent.remove();
        });
      }
    });
    getCategories.then(cats => {
      let cat = {
        all: {
          id: 'all', href: '/news/?mode=list', name: 'ALL', turn: 0
        },
        other: {
          id: 'other', href: '/news/?mode=list&cat=0', name: 'その他', turn: 99
        }
      }
      cats = [cat.all, ...cats, cat.other];
      const makeHTML = cat => `<li class="block-news__categories-item is-${cat.id}"><a href="${cat.href}">${cat.name}</a></li>`;
      categoryContainer.forEach(obj => {
        obj.innerHTML = '';
        cats.forEach(cat => obj.insertAdjacentHTML('beforeend', makeHTML(cat)));
      });
    });

  }

  fetchCategories(content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e) });
  }

}

const domReady = TPL => {
  slider();
  const $concept = d.querySelector('.concept');
  const $conceptContent = d.querySelector('.concept__content');
  const $conceptPara = d.querySelector('.concept__gallery--sp__para-block');
  const $imgConceptPara = $conceptPara.querySelectorAll('.gallery--sp__para');
  const $imgGallery4 = d.querySelector('.concept__title-image .deco4');

  // concept-block parallax sp
  gsap.to($imgConceptPara, {
    y: (i, el) => (-1 * parseFloat(el.getAttribute('data-speed'))) * ($concept.offsetHeight / 3),
    ease: "none",
    scrollTrigger: {
      trigger: $concept,
      invalidateOnRefresh: true,
      scrub: 0,
      // markers: true,
      start: 'center bottom'
    },
  });

  gsap.to($imgGallery4, {
    y: (i, el) => (-1 * parseFloat(el.getAttribute('data-speed'))) * ($conceptPara.offsetHeight / 3),
    ease: "none",
    scrollTrigger: {
      trigger: $conceptPara,
      invalidateOnRefresh: true,
      scrub: 0,
      // markers: true,
      start: 'center bottom'
    },
  });

  // concept-block parallax desktop
  const $imageParallax = $conceptContent.querySelectorAll('img');


  gsap.to($imageParallax, {
    y: (i, el) => (-1 * parseFloat(el.getAttribute('data-speed'))) * ($conceptContent.offsetHeight / 3),
    ease: "none",
    scrollTrigger: {
      trigger: $conceptContent,
      invalidateOnRefresh: true,
      scrub: 0,
      // markers: true,
      start: 'center bottom'
    },
  });

  // technology block animation
  const technologies = d.querySelectorAll('.technology__content');
  function animateFrom(elem, direction) {
    direction = direction || 1;
    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = -200;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 200;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
      duration: 1.5,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "expo",
      overwrite: "auto"
    });
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }


  gsap.utils.toArray(technologies).forEach(function (elem) {
    hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      onEnter: function () { animateFrom(elem) },
      onEnterBack: function () { animateFrom(elem, -1) },
      onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
    });
  });
  
  new BLOCKS();
};

const jqInit = () => false;

export default function HOME() {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail));

  $(() => jqInit());
}